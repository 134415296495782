import {useAdminGetJobSpecificationsQuery} from "../../apollo/generated";
import React from 'react'
import Autocomplete from "./Autocomplete";

import Loading from './Loading';

interface JobTypeSelectorProps {
  value: string[]
  onChange: (value: string[]) => void
}

const JobTypeSelector: React.FC<JobTypeSelectorProps> = ({value, onChange}) => {
  const {data} = useAdminGetJobSpecificationsQuery()

  const handleChange = (e, newValue) => {
    debugger
    onChange(newValue);
  }

  const options = data && data.adminGetJobSpecifications ?
    data.adminGetJobSpecifications.map(job => job.title) :
    [];

  options.sort();

  return (
    <Autocomplete
      id="job-type-search"
      label="Job Type Search"
      multiple
      value={value}
      options={options}
      onChange={handleChange}
    />
  )
}

export default JobTypeSelector
