import React from 'react';
import TextField from '@material-ui/core/TextField';
import {
  AutocompleteProps as MUIAutocompleteProps,
  AutocompleteRenderGroupParams
} from '@material-ui/lab/Autocomplete';
import {Autocomplete as MUIAutocomplete} from '@material-ui/lab/';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ListSubheader from '@material-ui/core/ListSubheader';
import {useTheme, makeStyles} from '@material-ui/styles';
import {VariableSizeList, ListChildComponentProps} from 'react-window';
import {Typography} from '@material-ui/core';

const LISTBOX_PADDING = 8; // px

function renderRow(props: ListChildComponentProps) {
  const {data, index, style} = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: (style.top as number) + LISTBOX_PADDING,
    },
  });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef<HTMLDivElement>((props, ref) => {
  const {children, ...other} = props;
  const itemData = React.Children.toArray(children);
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {noSsr: true});
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child: React.ReactNode) => {
    if (React.isValidElement(child) && child.type === ListSubheader) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const useStyles = makeStyles({
  listbox: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
  root: {minWidth: 300}
});

const renderGroup = (params: AutocompleteRenderGroupParams) => [
  <ListSubheader key={params.key} component="div">
    {params.group}
  </ListSubheader>,
  params.children,
];

interface AutocompleteProps extends MUIAutocompleteProps {
  id: string
  label: string;
  renderOption?: (option: any) => JSX.Element | string
}

const defaultRenderOption = (option) => <Typography noWrap>{option}</Typography>

const Autocomplete: React.FC<AutocompleteProps> = (props) => {
  const classes = useStyles();


  return (
    <MUIAutocomplete
      id={props.id}
      disableListWrap
      classes={classes}
      ListboxComponent={ListboxComponent}
      renderGroup={renderGroup}
      groupBy={(option) => option[0].toUpperCase()}
      renderInput={(params) => <TextField {...params} variant="outlined" label={props.label}/>}
      renderOption={defaultRenderOption}
      {...props}
    />
  );
}

export default Autocomplete;
