import * as React from 'react'
import styled from 'styled-components'
import JobCardWithBids from '../jobCardWithBids'
import { Build, AccessTime } from '@material-ui/icons';
import JobTypeSelector from '../ui/JobTypeSelector';
import Loading from '../ui/Loading';
import { Tabs, Tab, Typography } from '@material-ui/core';
import { useQuery } from '@apollo/react-hooks';
import { ADMIN_GET_JOBS } from '../../apollo/queries/getUserProperties';
import SearchBar from '../ui/SearchBar';
import CountySelector from '../ui/countySelector';
import StateSelector from '../ui/StateSelector';
import Pagination from '../ui/Pagination';

const JobsContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: stretch;
	padding: 12px 40px 40px 40px;
	width: auto;
	height: auto;
	min-height: 100%;

	@media (max-width: 850px) {
		padding: 16px;
		padding-top: 16px;
		padding-bottom: 66px;
	}
`;

const deriveView = (tab: number) => {
  switch (tab) {
    case 4:
      return 'COMPLETED'
    case 3:
      return 'ONGOING'
    case 2:
      return 'BIDDING'
    case 1:
      return 'SLIPPING'
    case 0:
    default:
      return 'NORMAL'
  }
}

const AdminJobs = () => {
  const [jobType, setJobType] = React.useState<string[]>([])
  const [search, setSearch] = React.useState<string | undefined>(undefined)
  const [state, setState] = React.useState<string | undefined>('')
  const [county, setCounty] = React.useState<string | undefined>(undefined)

  const [tab, setTab] = React.useState(0)
  const handleTabChange = (e: React.ChangeEvent<{}>, newVal: number) => setTab(newVal)
  const [page, setPage] = React.useState(0)

  const variables = {
    page,
    county,
    jobType: jobType.length > 0 ? jobType : undefined,
    search: search || undefined,
    view: deriveView(tab)
  };

  const { data, loading, refetch } = useQuery(ADMIN_GET_JOBS, {variables});
  React.useEffect(() => {
    refetch(variables)
  }, [page, tab, search])

  React.useEffect(() => {
    if (!state) {
      setCounty('')
    }
  }, [state])

  if (loading && (!data || !data.adminGetJobs)) return <Loading />;

  return (
    <JobsContainer>
      <Searchers>
        <JobTypeSelector value={jobType} onChange={setJobType}/>
        <SearchBar label="User/Property Search" onSearch={setSearch} />
        <StateSelector value={state} onChange={(e) => setState(e.target.value as string)} />
        {state && <CountySelector allowNone onChange={(o) => setCounty(o.value)} theState={state} />}
      </Searchers>
      <Typography variant="body2" align="center" style={{color: 'white'}}>This is an early alpha portal, most features will not work. Bid inviting works just fine though!</Typography>
      <Tabs
        style={{marginBottom: 24}}
        value={tab}
        onChange={handleTabChange}
        centered
      >
        <Tab icon={<Build />} style={{ color: 'white' }} label={`Jobs (${data.adminGetJobsCount})`} />
        <Tab icon={<AccessTime />} style={{ color: 'white' }} label={`Slipping (${data.adminGetSlippingJobsCount})`} />
        <Tab icon={<AccessTime />} style={{ color: 'white' }} label={`Bidding (${data.adminGetBiddingJobsCount})`} />
        <Tab icon={<AccessTime />} style={{ color: 'white' }} label={`Ongoing (${data.adminGetOngoingJobsCount})`} />
        <Tab icon={<AccessTime />} style={{ color: 'white' }} label={`Completed (${data.adminGetCompletedJobsCount})`} />
      </Tabs>
      {data.adminGetJobs.map((job) => (
        <JobCardWithBids
          refetch={refetch}
          renoworksId={job.property.renoworksId}
          legacyHomeSpecId={job.property.legacyHomeSpecId}
          key={job.id!}
          job={job}
          extraInfo={`${job.property.owner.firstName} ${job.property.owner.lastName} • ${job.property.owner.user.email} • 
          ${job.property.houseNumber} ${job.property.streetName}, 
          ${job.property.city}, ${job.property.state} ${job.property.zipCode}`}
        />
      ))}
      <Pagination count={(tab === 0 ? data.adminGetJobsCount : data.adminGetSlippingJobsCount) || 0} page={page} setPage={p => setPage(p)} />
    </JobsContainer>
  )
}

export default AdminJobs

const Searchers = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-end;
  background: white;
  padding: 0 12px 12px 12px;
  border-radius: 4px;
  margin-bottom: 12px;

  > * {
    margin-left: 12px
  }
`
