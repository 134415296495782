import React from 'react'
import Select from 'react-select'

import styled from 'styled-components'
import { useQuery } from '@apollo/react-hooks'
import { GET_STATES_WITH_COUNTIES } from '../../apollo/queries'
import { StyledFormControlForSelect } from '../sharedStyles'
import Loading from './Loading';

const StyledSelect = styled(Select) <{ lowOpacity?: boolean }>`
  opacity: ${({ lowOpacity }) => lowOpacity ? .7 : 1};
  color: black;
`;

interface Option {
  value: string
  label: string
}
interface State {
  name: string
  counties: Option[]
}
interface GetStatesWithCounties {
  states: State[]
}

interface Props {
  onChange: (option: Option) => void
  theState: string
  isDisabled?: boolean
  style?: any
  allowNone?: boolean

  // If portalTarget is undefined, document.body will be used. Set portalTarget to null to disable
  // the portal.
  portalTarget?: HTMLElement | null
}

const CountySelector = ({ onChange, theState, isDisabled, style, portalTarget, allowNone }: Props) => {
  const {
    data: { states }, loading,
  } = useQuery<GetStatesWithCounties>(GET_STATES_WITH_COUNTIES)
  if (loading) return <StyledFormControlForSelect style={style}><Loading dark relative /></StyledFormControlForSelect>;
  const countyObj = states.find(state => state.name === theState)
  const selectStyles = {
    input: base => ({
      ...base,
      '& input': {
        font: 'inherit',
      },
    }),
    menuPortal: portalTarget === null ? undefined : base => ({ ...base, zIndex: 9999 })
  };

  let counties = countyObj ? countyObj.counties : []
  if (allowNone) {
    counties = [{label: 'None', value: ''}, ...counties]
  }
  return (
    <StyledFormControlForSelect style={style}>
      <StyledSelect
        isDisabled={isDisabled}
        lowOpacity={isDisabled}
        placeholder={theState ? "Select your county" : "County (select a state first)"}
        onChange={(selectedOption) => onChange(selectedOption)}
        options={theState ? counties : []}
        styles={selectStyles}
        menuPortalTarget={portalTarget === undefined ? document.body : portalTarget}
      />
    </StyledFormControlForSelect>
  );
}

export default CountySelector;