import React from 'react'
import DisastrousErrorHandler from '../../components/ui/DisastrousErrorHandler';
import AuthGuard from '../../components/ui/AuthGuard';
import AdminSidebar from '../../components/adminSidebar';
import AdminJobs from '../../components/admin/Jobs';

const AdminMain = () => (
  <DisastrousErrorHandler>
    <AuthGuard allowed="ADMIN">
	<AdminSidebar>
		<AdminJobs />
	</AdminSidebar>
    </AuthGuard>
  </DisastrousErrorHandler>
);


export default AdminMain;
